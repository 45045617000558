import React, { useEffect, Fragment } from "react"
import ImageDarkTextRightWithButton from "./components/imageDarkTextRightWithButton"
import ImageDarkTextLeftWithButton from "./components/imageDarkTextLeftWithButton"
import CTABusiness from "../../general/ctaBusiness"
import FAQList from '../../general/faqsList';
import InnerPageHeader from "./components/innerPageHeader";
import { scrollToElement } from "../../../../utility/utils"
import DarkBelowFoldCards from "../../general/darkBelowFoldCards";
import MoreForYouComponent from "../../general/more-for-you";

import KudaPOSTerminalIcon from "../../../../../assets/img/more-for-you/kuda-pos-icon.inline.svg"
import InvoicingIcon from "../../../../../assets/img/more-for-you/kuda-invoicing.inline.svg"
import BizSalaryLoanIcon from '../../../../../assets/img/more-for-you/kuda-bizSalaryLoan.icon.inline.svg'

import EntryIllustration from '../../../../../assets/img/cashback-hero-business.svg'
import EntryIllustrationBlur from "../../../../../assets/img/business/blur/cashback-blur.svg"
import FlatFeeIcon from "../../../../../assets/img/kuda-flat-fee.inline.svg"
import InvoiceIcon from "../../../../../assets/img/fund-with-cash-green.inline.svg"
import SpendIcon from '../../../../../assets/img/kuda-money-business.inline.svg'

// track due invoices images
import FirstSectionImage from "../../../../../assets/img/cashback-business-hand.svg"
// entry content images
import SecondSectionImage from "../../../../../assets/img/cashback-business-phone.svg"
// entry content images
import ThirdSectionImage from "../../../../../assets/img/cashback-business-notifications.svg"

import { Img } from "react-image";


const entryContent = {
  title: (<span>
    Make money from 
    <span className="color-secondary"> airtime and bill </span>  payments on Kuda Business!
  </span>),
  subtitle: "Whether you’re buying for yourself or selling, we’ll pay you 1.5% cashback on every airtime and bill payment you make.",
  name: "Download Kuda Buisness",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
}

const businessTopFeatures = [
  {
    icon: <FlatFeeIcon />,
    text: "Increase your streams of income."
  },
  {
    icon: <SpendIcon />,
    text: "Sell airtime to make more money."
  },
  {
    icon: <InvoiceIcon />,
    text: "Earn 1.5% cashback instantly."
  },
]

const firstSection = {
  title: "Increase your streams of income.",
  subtitle: "Make money from cashbacks on every airtime and bill payment you make with your Kuda Business account.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
  name: "Join Kuda Buisness",
  illustration: (
    <Img src={FirstSectionImage} className="kuda-business-image" />

  ),
}

const secondSection = {
  title: "Sell airtime to make more money.",
  subtitle: "Considering being an airtime vendor? Sell airtime with your Kuda Business account to make extra money from our 1.5% cashback.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
  name: "Download Kuda Buisness",
  illustration: (

    <Img src={SecondSectionImage} className="kuda-business-image" />

  ),
}

const thirdSection = {
  title: "Earn 1.5% cashback instantly.",
  subtitle: "Get your cashback whenever you buy airtime or pay a bill. We’ll credit your account immediately your transaction is completed.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
  name: "Join Kuda Buisness",
  illustration: (

    <Img src={ThirdSectionImage} className="kuda-business-image" />

  ),
}


const questions = [
  {
    heading: "What is a cashback?",
    list: (
      <span className="flex flex-column  mt-2">

        <span className="faq-description f-16">A cashback is an incentive that allows you to earn back a percentage of the money you spend on a purchase. With a full Kuda Business account, you earn back 1.5% of your spend on any airtime or bill payment.</span> <br />

       </span>),
    index: 1
  },
  {
    heading: "When do I get a cashback?",
    list: (
      <span className="flex flex-column  mt-2">
        <span className="faq-description f-16">You get a cashback as soon as your airtime or bill payment is completed. We’ll credit your Kuda Business account with the cashback instantly. </span>

      </span>),
    index: 2
  },
  {
    heading: "How do I qualify for a Kuda Business cashback?",
    list: (
      <div className="flex flex-column  mt-2">
        <span className="faq-description f-16">You just need to open a Kuda Business account, then buy airtime or pay a bill to get a 1.5% cashback instantly in your Kuda Business account.
        </span>

      </div>),
    index: 3
  },
  {
    heading: "Is there a limit to how much cashback I can earn?",
    list: (
      <div className="flex flex-column  mt-2">
        <span className="faq-description f-16">
        No, there’s no limit to how much cashback you can earn. We’ll pay you a 1.5 cashback on every airtime or bill payment that you make. <a target="_blank" rel="noopener noreferrer"  className="cursor-pointer link-decoration" href="https://kuda.com/incentive-program/business-cashback/">Terms and conditions apply</a>.
        </span>

      </div>),
    index: 4
  }
]

const moreForYou = [
  {
    icon: <KudaPOSTerminalIcon />,
    title: "POS",
    subText: `Buy a physical POS, lease it for a 
    one-time fee, or get a virtual POS to accept payments quickly.`,
    linkTo: `/en-ng/business/pos/`
  },
  {
    icon: <InvoicingIcon />,
    title: "Invoicing",
    subText: `Create and manage invoices easily, and sync your product catalogue for accurate pricing.`,
    linkTo: `/en-ng/business/invoicing/`
  },
  {
    icon: <BizSalaryLoanIcon />,
    title: "Salary Loan",
    subText: `Offer your staff loans with easy application, approval and disbursement through Kuda Business.`,
    linkTo: `/en-ng/business/salary-loan/`
  },

]

const BusinessCashbackAirtimeBills = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  return (

    <Fragment>

      <InnerPageHeader
        title={entryContent.title}
        subtitle={entryContent.subtitle}
        buttonName={entryContent.name}
        buttonUrl={entryContent.url}
        entryIllustration={EntryIllustration}
        fallback={<Img className="hero-illustration" src={EntryIllustrationBlur}/>}
      />
      <DarkBelowFoldCards topFeatures={businessTopFeatures} />
      <ImageDarkTextRightWithButton
        title={firstSection.title}
        subtitle={firstSection.subtitle}
        illustration={firstSection.illustration}
        name={firstSection.name}
        url={firstSection.url}
        isExternal={true}
      />
      <ImageDarkTextLeftWithButton
        title={secondSection.title}
        subtitle={secondSection.subtitle}
        illustration={secondSection.illustration}
        name={secondSection.name}
        url={secondSection.url}
        isExternal={true}
      />
      <ImageDarkTextRightWithButton
        title={thirdSection.title}
        subtitle={thirdSection.subtitle}
        illustration={thirdSection.illustration}
        name={thirdSection.name}
        url={thirdSection.url}
        isExternal={true}
      />

      {/* FAQ for invoice here */}
      <FAQList title={"Cashback FAQs"} questions={questions} />
      <MoreForYouComponent moreForYou={moreForYou}/>
      <CTABusiness />
    </Fragment>
  )
}

export default BusinessCashbackAirtimeBills

