import React from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import BusinessCashbackAirtimeBills from "../../../components/body/pages/en-ng/business/cashback-airtime-bills"

const CashbackAirtimeBills = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/business/cashback-airtime-bills/"}
      title="Cashback on Airtime and Bills| Get 1.5% Cashback on Airtime and Bill Payments | Kuda Business"
      description="Unlock 1.5% cashback on airtime and bill payments with Kuda Business. Save more while managing your finances effortlessly. Join Kuda Business."
    />
    <BusinessCashbackAirtimeBills />
  </Layout>
)

export default CashbackAirtimeBills
